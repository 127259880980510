<template>

<div class="home">
    <section class="image-gallery">
        <div class="image" v-for="item in items" :key="item.id">
            <h2>{{   item.title   }}</h2>
            <img :src="item.path">
            <p>{{   item.description   }}</p>
        </div>
    </section>
</div>

</template>

<script>

import axios from 'axios';

export default {
    name: 'Home',
    
    data() {
        return {
            items: []
        }
    },

    created() {
        this.getItems();
    },

    methods: {
        async getItems() {
            try {
                let response = await axios.get("/api/items");
                this.items = response.data;
                return true;
            } catch (error) {
                // console.log(error);
            }
        }
    }
}

</script>

<style scoped>

*,
*:before,
*:after {
    box-sizing: inherit;
}

/* Masonry */
.image-gallery {
    column-gap: 1.5em;
}

.image {
    margin: 0 0 1.5em;
    display: inline-block;
    width: 100%;
}

.image h2 {
    font-style: italic;
}

.image img {
    width: 100%;
}

.image p {
    font-size: small;
    margin: 0;
    color: rgb(83, 83, 83);
}

/* Masonry on large screens */
@media only screen and (min-width: 1024px) {
    .image-gallery {
        column-count: 4;
    }
}

/* Masonry on medium screens */
@media only screen and (max-width: 1023px) and (min-width: 768px) {
    .image-gallery {
        column-count: 3;
    }
}

/* Masonry on large screens */
@media only screen and (max-width: 767px) and (min-width: 540px) {
    .image-gallery {
        column-count: 2;
    }
}

</style>